.root-nav-sidebar {
    height: 100%;
    width: 80px;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: black;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 0px;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 1;
}

.root-nav-sidebar:hover {
    width: 250px;
}

.root-nav-sidebar-a {
    white-space: nowrap;
}

.root-nav-sidebar-icon {
    margin-left: 25px;
    margin-right: 25px;
}

.root-nav-sidebar-account {
    height: 100px;
}

.root-nav-sidebar-account-list {
    margin-left: 80px;
}

.content {
    height: 100%;
    width: calc(100% - 80px);
    position: fixed;
    top: 0px;
    left: 80px;
}


.textLayer {
    font-family: sans-serif;
    font-size: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    /* color: transparent; */
    transform-origin: 0% 0%;
    transition: opacity 0.5s 0.1s;
  }

  .textLayer > div {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
  }
  
  
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select-selector input:focus {
  box-shadow: none !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: safe center; /* https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container */
  overflow: scroll; 
  line-height: 1.5;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap;  */
  /* Prevent wrapping to a new line */
  /* Hide scrollbar for all browsers */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.ag-row .ag-cell::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}